<template>
    <div class="wrapper">
        <div class="collbtn" :class="visible ? null : 'collapsed' && getShowProfileInOverview ? 'clickable' : null"
            :aria-expanded="visible ? 'true' : 'false'" :aria-controls="`collapse-${category.id}`">
            <section id="category">
                <div class="category">
                    <div style="text-align: right;">
                        {{ category.inquiry }}
                    </div>
                    <div v-if="getShowProfileInOverview">
                        <div v-if="getCategoryImplications_1(category) > 0 || getCategoryImplications_2(category) > 0">
                            <!-- progress bar has at least some colored elements besides gray -->
                            <ProgressBar :segments_total=maxLength :segments_1=getCategoryImplications_1(category)
                                :segments_2=getCategoryImplications_2(category) />
                        </div>
                        <div v-else>
                            <!-- progress bar is gray only -->
                            <ProgressBar :segments_total=maxLength :segments_1=0 :segments_2=0 />
                        </div>
                    </div>
                    <div v-if="!getShowProfileInOverview">
                        <ProgressBar :segments_total=maxLength :segments_1=0 :segments_2=0 />
                    </div>
                    <div>
                        {{ answeredQuestions }} / {{ maxLength }}
                    </div>
                    <div>
                        <b-icon v-if="getShowProfileInOverview" class="clickable not-rotate" font-scale="1.5"
                            :icon="starred ? 'star-fill' : 'star'" :style="{ color: starred ? this.activeColor : 'black' }"
                            @click="toggleIsStarred"></b-icon>
                        <b-icon v-if="!getShowProfileInOverview" class="not-rotate" font-scale="1.5"
                            :icon="starred ? 'star-fill' : 'star'"
                            :style="{ color: starred ? this.activeColor : 'black' }"></b-icon>
                    </div>
                    <div>
                        <b-icon v-if="getShowProfileInOverview" font-scale="1.5" icon="chevron-down"
                            @click="visible = !visible"></b-icon>
                    </div>
                </div>
            </section>
        </div>

        <b-collapse :id="`collapse-${category.id}`" v-model="visible" class="mt-2 mb-2" height="100px">
            <div class="category-answers">
                <b-card
                    style="padding:12px; margin: 0 16px 0 32x; border: none; height: fit-content; justify-content: center">
                    <p>{{ category.short }}</p>
                    <router-link :to="`/elsi_cards/${category.inquiry}`" from="test">
                        <Button style="width: 100%;" label="Zur ELSI-Karte" :chevron="true" />
                    </router-link>
                </b-card>
                <div class="answers">
                    <p><b>Fragen und Ihre Antworten, die diese Kategorie beeinflussen</b></p>
                    <div v-for="answer, index in category.answers" :key="index">
                        <b-card class="answer">
                            <b-card-title class="answer-title">
                                <span>{{ answer.questionId }}.</span>
                                <span v-html="answer.questionInquiry"></span>
                            </b-card-title>
                            <div v-for="option in answer.options" :key=option.optionId>
                                <b-card-text class="answer-body-line">
                                    <b-icon icon="circle-fill" font-scale="1"
                                        :style="{ color: getColorOfPoints(option.points) }" />
                                    <span>{{ option.name }}</span>
                                </b-card-text>
                            </div>
                        </b-card>
                    </div>
                </div>
            </div>
        </b-collapse>
    </div>
</template>

<script>

import ProgressBar from "./ProgressBar.vue"
import Button from "./Button.vue";

export default {
    name: "collapse-profile-category",
    components: {
        ProgressBar,
        Button
    },
    props: {
        category: Object,
    },
    computed: {
        categories() {
            return this.$store.getters['kb/getAllCategories']
        },
        starred() {
            return this.category.isStarred
        },
        getShowProfileInOverview() {
            return this.$store.getters['survey/getShowProfileInOverview']
        },
        getCategoriesByTermId() {
            return this.$store.getters['kb/getCategoriesByTermId'](this.term)
        },

    },
    methods: {
        getColorOfPoints(points) {
            let color = 'grey'
            switch (points) {
                case 1:
                    color = '#278CB9'
                    break;
                case 2:
                    color = '#A1DBED'
                    break;
                case 0:
                    color = '#ECF0F3'
                    break;
            }
            return color

        },
        projectReflection() {
            return this.$store.getters['reflection/getProjectReflection']
        },
        toggleIsStarred() {
            const payload = { categoryId: this.category.id, value: !this.category.isStarred }
            this.$store.dispatch('survey/toggleIsStarredCategory', payload)
        },
        getCategoryImplicationsLength(category) {
            return this.$store.getters['survey/getCategoryImplicationsLength'](category)
        },
        getCategoryImplications_1(category) {
            return this.$store.getters['survey/getCategoryImplications_1'](category)
        },
        getCategoryImplications_2(category) {
            return this.$store.getters['survey/getCategoryImplications_2'](category)
        },
    },

    beforeMount() {
        let implicationsOne = []
        let implicationsTwo = []
        this.category.implications.forEach((implication) => {
            if (implication.implication.points === 1) {
                implicationsOne.push(implication)
            }
            if (implication.implication.points === 2) {
                implicationsTwo.push(implication)
            }
        }
        )
        this.segment1 = implicationsTwo.length
        this.segment2 = implicationsOne.length

    },
    data() {
        return {
            visible: false,
            maxLength: this.category.maxAnswers.length,
            segment1: 0,
            segment2: 0,
            answeredQuestions: this.category.answers.length,
            activeColor: '#F2AF4A',
        }
    }
}
</script>

<style lang="scss" scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0.4;
    filter: alpha(opacity=50);
    background-color: gray;
}

.answers {
    overflow-y: scroll;
    max-height: 450px;
    padding-bottom: 32px;
    margin-bottom: 32px;
    margin-left: 20px;
}


.answer {
    height: auto;
    border: none;
    margin-bottom: 12px
}

.answer-title {
    display: grid;
    grid-template-columns: 24px auto;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 8px;
}

.answer-body-line {
    display: grid;
    grid-template-columns: 24px auto;
    margin-left: 24px;
    align-items: center;
}

.category-answers {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 20% 80%;
    padding-top: 32px;
    padding-bottom: 32px;
    height: auto;
    max-height: 500px;
}

.wrapper {
    margin-bottom: 1rem;
}

section#category {
    display: flex;
    width: 100%;
    flex-direction: column;

    .category {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 15% 70% 5% 2% 5%;
        grid-column-gap: 1.5rem;
        height: 5rem;
        align-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;

    }
}


.collbtn {
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;

    /* Card */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .b-icon.bi,
    .btn .b-icon.bi {
        font-size: inherit;
        transition: 0.1s;
    }

    &[aria-expanded="true"] .b-icon:not(.not-rotate) {
        transform: rotate(180deg);
    }
}
</style>
