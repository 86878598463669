<template>
    <div id="bar" :style="[nogap ? { 'gap': '0' } : {}]">
        <div v-for="index in segments_1" :key="'1-' + index" class="segment segment_1 colored"></div>
        <div v-for="index in segments_2" :key="'2-' + index" class="segment segment_2 colored"></div>
        <div v-for="index in filler_segments()" :key="'0-' + index" class="segment"></div>
    </div>
</template>

<script>
export default {
    name: "ProgressBar",
    props: {
        segments_total: {
            type: Number,
            required: true
        },
        segments_1: {
            type: Number,
            default: 0
        },
        segments_2: {
            type: Number,
            default: 0
        },
        max_width: String,
        max_height: String,
        nogap: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        filler_segments() {
            return this.segments_total - this.segments_1 - this.segments_2
        }
    }
}
</script>

<style lang="scss" scoped>
#bar {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-items: stretch;
    justify-content: space-between;
    gap: 0.4%;
    flex: initial;
    background-color: $elsi-color-bar-bg;
    height: 20px;
    clip-path: inset(0 0 0 0 round 10px);

    .segment {
        flex: 1;
        height: 20px;
        background-color: $elsi-color-bar-bg;
    }

    .segment_1 {
        background-color: $elsi-color-blue;
    }

    .segment_2 {
        background-color: #A1DBED;
    }

}
</style>
